import { computed } from 'vue';
import { type RouteRecordRaw } from 'vue-router';

import { useGlobalI18n } from '@abaninja/util-i18n';

const { global: { t } } = useGlobalI18n();

export const routes: RouteRecordRaw[] = [
  {
    path: 'support',
    meta: {
      title: computed(() => t('messages.support')),
    },
    children: [
      {
        path: '',
        name: 'support',
        component: () => import('../SupportIndex.vue'),
      },
      {
        path: 'info',
        name: 'support-info',
        component: () => import('../SupportInfoIndex.vue'),
      },
    ],
  },
];
